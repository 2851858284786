<template>
  <MoleculesSelectDropdown
    ref="occupation"
    v-model="currValue"
    model-type="dropdown"
    :wrapper-class="wrapperClass"
    :label="label"
    :name="$attrs.name || 'occupation'"
    :placeholder="$attrs.placeholder || 'Select occupation'"
    :data-list-display="dataListDisplay"
    :data-input-display="dataInputDisplay"
    :data-value="dataValue"
    :error-message="errorMessage"
    :character-delay="characterDelay"
    :data-src="`${apiEndpoint}/occupation`"
    :validate-on-focus="true"
    :theme="theme"
    :hide-dropdown-icon="hideDropdownIcon"
    :dropdown-class="dropdownClass"
    searchable
    @validation="handleValidation"
  />
</template>

<script setup>
defineOptions({
  name: 'MoleculesOccupation'
})

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: null
  },

  label: {
    type: String,
    default: 'Occupation'
  },

  /**
   * show dropdown list delay time
   */
  characterDelay: {
    type: [String, Number],
    default: 0,
    validator: value => !isNaN(value)
  },

  /**
   * key used to display data in dropdown
   */
  dataListDisplay: {
    type: [String, Number],
    default: 'name'
  },

  /**
   * key used to display data in <input>
   */
  dataInputDisplay: {
    type: [String, Number],
    default: 'name'
  },

  /**
   * key used for emitting data to parent component
   * if value is empty it return the whole selected item
   */
  dataValue: {
    type: [String, Number],
    default: 'name'
  },

  errorMessage: {
    type: String,
    default: ''
  },

  hideDropdownIcon: {
    type: Boolean,
    default: false
  },

  theme: {
    type: String,
    default: 'default'
  },

  wrapperClass: {
    type: String,
    default: ''
  },

  dropdownClass: {
    type: String,
    default: ''
  }
})

const emit = defineEmits([
  'update:modelValue',
  'occupation-validation',
  'update'
])

const { apiEndpoint } = useEnv()

const currValue = proxyModel(props, 'modelValue')

watch(() => currValue.value, val => {
  emit('update', val)
})

function handleValidation (value) {
  emit('occupation-validation', value)
}
</script>
